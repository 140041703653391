<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <PasswordReset @reset="redirect" />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import PasswordReset from '../../components/passreset/passwordreset.vue';

export default {
  components: {
    PasswordReset
  },
  methods: {
    redirect() {
      let token = this.$route?.params?.token;
      window.open(
        `https://connect2.preludeconnect.com/login/${token}`,
        '_self'
      );
    }
  }
};
</script>
