<template>
  <v-card>
    <v-card-title>{{ title }}</v-card-title>
    <v-card-text>
      <template v-if="!isUpdating">
        <h2>Welcome to Prelude Connect</h2>
        <p>
          If this is your first time logging in, please set a password for your
          Connect account. Your login will always be your email address.
        </p>
        <p>Once set you will be redirected to your new Connect account!</p>
      </template>
      <v-form v-model="isFormValid" v-on:submit.prevent>
        <v-text-field
          v-model="pass1"
          hint="At least 8 characters"
          :type="show1 ? 'text' : 'password'"
          :rules="[rules.required, rules.min, rules.password]"
          label="Please enter your new password"
          counter
          :disabled="viewPartner"
        ></v-text-field>
        <v-text-field
          v-model="pass2"
          hint="At least 8 characters"
          :type="show1 ? 'text' : 'password'"
          :rules="[passwordConfirmationRule]"
          label="Please verify your new password"
          counter
          :disabled="viewPartner"
        ></v-text-field>
        <v-btn :disabled="!isFormValid" color="primary" @click="resetPass()">
          Update
        </v-btn>
      </v-form>
      <p>
        For any issues please contact
        <a href="email:support@preludeconnect.com">
          Support@preludeconnect.com
        </a>
      </p>
    </v-card-text>
  </v-card>
</template>
<script>
import { auth, partner } from '../../sharedPlugin';
import axios from 'axios';

export default {
  data() {
    return {
      title: null,
      data: [],
      pass1: null,
      pass2: null,
      isFormValid: false,
      message: '',
      showSnackBar: false,
      color: '',
      show1: false,
      isUpdating: false,
      rules: {
        required: (value) => !!value || 'Required.',
        min: (v) => v?.length >= 8 || 'Min 8 characters',
        password: (value) => {
          const pattern =
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;
          return (
            pattern.test(value) ||
            'Min. 8 characters with at least one capital letter, a number and a special character.'
          );
        }
      },
      resetType: ''
    };
  },
  computed: {
    ...auth.mapComputed(['user']),
    ...partner.mapComputed(['getPartner']),
    viewPartner() {
      return this.getPartner?.viewPartner;
    },
    passwordConfirmationRule() {
      return () => this.pass1 === this.pass2 || 'Password must match';
    }
  },
  async mounted() {
    this.isUpdating = this.$route.name === 'SetPassword' ? false : true;

    this.title = this.isUpdating ? 'Update Password' : 'Set your Password';
    await this.getPatientInfo();
  },

  methods: {
    showMessage({ message, color }) {
      this.color = color;
      this.message = message;
      this.showSnackBar = true;
    },
    async resetPass() {
      await axios
        .put(
          `${process.env.VUE_APP_API_URL}/api/v1/connect/auth/reset`,
          {
            id: this.user.patientId,
            password: this.pass1
          },
          {
            headers: {
              'x-api-key':
                '58b54801044df5a0536c25db11c653c743761b2db21e044aedb9f91caa6138a5'
            }
          }
        )
        .then((response) => {
          this.$store.commit('showSnackbar', {
            message: 'Your password has been updated',
            color: 'green'
          });
          this.pass1 = null;
          this.$emit('reset');
        })
        .catch((err) => {
          console.error(err);
          this.$store.commit('showSnackbar', {
            message:
              'There was an error updating your password. Please try again.',
            color: 'red'
          });
        });
    },
    async updatePatient() {},

    async getPatientInfo() {
      return await axios
        .post(
          `${process.env.VUE_APP_API_URL}/api/v1/inova/patients/getConnectPatient`,
          {
            connectId: this.user.userId
          },
          {
            headers: {
              'x-api-key':
                '58b54801044df5a0536c25db11c653c743761b2db21e044aedb9f91caa6138a5'
            }
          }
        )
        .then((response) => {
          this.data = response.data[0];
          this.showMessage({
            message: 'Your profile was updated.',
            color: 'green'
          });
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }
};
</script>
